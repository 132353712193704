.conversations-container {
        width:70%;
        margin: auto;
        display: flex;
        flex-direction: column;
}



.conversation {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.profile-pic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
}

.conversation-details {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.user-name {
    font-size: 1.1em;
    font-weight: bold;
    margin: 0;
}

.last-message {
    font-size: 0.95em;
    color: #666;
    margin: 5px 0;
}

.timestamp {
    font-size: 0.8em;
    color: #999;
    align-self: flex-end;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .conversations-container {
        padding: 10px; /* Less padding on smaller screens */
    }

    .profile-pic {
        width: 40px; /* Smaller profile picture */
        height: 40px; /* Smaller profile picture */
        margin-right: 10px; /* Adjust margin */
    }

    .user-name {
        font-size: 1em; /* Smaller user name */
    }

    .last-message {
        font-size: 0.9em; /* Smaller last message text */
    }

    .timestamp {
        font-size: 0.7em; /* Smaller timestamp */
    }
}
