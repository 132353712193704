.body {
  position: relative; /* Necessary to position the pseudo-element */
  overflow: hidden; /* Prevents overflow of pseudo-element */
}

.body::before {
  content: '';
  position: absolute; /* Position it absolutely within the parent */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(https://cdn.discordapp.com/attachments/1043757756743680070/1293401326612647937/tomfar_explosion_of_colors_painting_by_matisse_2cf3245e-d2b0-45b2-8725-e6117ccf38ce.png?ex=67073d5a&is=6705ebda&hm=2a148b3a2e626f4c8e0bcc9861109ae16ea07ff654534f49f60015b6d0b69e68&);
  background-size: cover; /* Cover the entire area */
  background-position: center; /* Center the image */
  opacity: 0.5; /* Set opacity here */
  z-index: 0; /* Ensure it's behind other content */
}

.container {
  position: relative; /* Keep the container above the background */
  z-index: 1; /* Ensure it sits above the pseudo-element */
}


.hp-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-image: url('../../public/oasis2.png');
  background-position: center center;
  background-size: cover;
  /* Cover the entire element */
  /* background-position: center; Center the background image */

}



.transparent-bg {
  background-color: rgba(255, 255, 255, 1); /* White with 80% opacity */
  padding: 20px; /* Optional padding */
  border-radius: 8px; /* Optional rounded corners */
  z-index: 1; /* Ensure it stays above the background */
}




.profile-bubble {
  background-color: #a9acb0; /* Grey background */
  color: white;              /* White text */
  padding: 5px 10px;         /* Padding for the bubble effect */
  border-radius: 50px;       /* Rounded edges */
  text-decoration: none;     /* Remove underline */
  display: inline-block;
  font-size: small;  
  text-align: right;   /* Ensures it behaves like a bubble */
}