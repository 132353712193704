.chat-container {

    width: 70%;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
  }
  
  .chat-header {
    text-align: center;
    font-size: 1.2em;
    color: #333;
    margin-bottom: 15px;
  }

  .message-list {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column; /* Stack messages vertically */
  }
  
  .message-bubble {
    max-width: 70%;
    padding: 10px 15px;
    border-radius: 15px;
    margin-bottom: 15px; /* Vertical space between bubbles */
    word-wrap: break-word;
  }
  
  .message-bubble.sent {
    background-color: #daf8e3;
    align-self: flex-end; /* Aligns sent messages to the right */
    border-top-right-radius: 0;
  }
  
  .message-bubble.received {
    background-color: #f1f0f0;
    align-self: flex-start; /* Aligns received messages to the left */
    border-top-left-radius: 0;
  }
    
  .message-content {
    margin: 0;
    font-size: 1em;
    color: #333;
  }
  
  .message-timestamp {
    font-size: 0.75em;
    color: #999;
    margin-top: 5px;
    display: block;
  }
  
  .message-input-container {
    display: flex;
    gap: 10px;
    padding-top: 10px;
    border-top: 1px solid #ddd;
  }
  
  textarea {
    flex: 1;
    resize: none;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  button {
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    background-color: #4caf50;
    color: white;
    cursor: pointer;
  }
  
  button:disabled {
    background-color: #ccc;
  }
  