/* MultiSelectDropdown.css */
.multi-select-dropdown {
    position: relative;
    width: 100%;
  }
  
  .dropdown-header {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .dropdown-header .arrow {
    transition: transform 0.2s ease;
  }
  
  .dropdown-header .arrow.open {
    transform: rotate(180deg);
  }
  
  .adropdown-menu {
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    max-height: 200px;
    overflow-y: auto;
  }
  
  .dropdown-item {
    padding: 8px;
    cursor: pointer;
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0;
  }
  
  .dropdown-item.selected {
    background-color: #007bff;
    color: white;
  }
  